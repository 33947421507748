import BackgroundImage from 'components/background/BackgroundImage';
import ExpandWrapper from 'components/wrappers/Animations/ExpandWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { mediaQueries } from 'config/theme/breakpoints';
import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

// Pure CMS data transformation before we send it to the "real" block!
const ImageFullscreenBlock = ({ content = {}, isColumn = false }) => {
    const { background_portrait_landscape: backgroundPortraitLandscape } = content;

    // Transform image data
    const isAboveTabletSm = useAboveBreakpoint('tabletSm');
    const transformedBackground = isAboveTabletSm
        ? transformBackgroundImage(backgroundPortraitLandscape.landscape?.background_image)
        : transformBackgroundImage(backgroundPortraitLandscape.portrait?.background_image);

    if (!transformedBackground) {
        return null;
    }

    return (
        <ExpandWrapper active={!isColumn}>
            <BackgroundImage
                src={transformedBackground.url}
                position={transformedBackground.position}
                size={transformedBackground.backgroundSize}
                query={mediaQueries.fullWidth}
            />
        </ExpandWrapper>
    );
};

ImageFullscreenBlock.propTypes = {
    content: PropTypes.shape({
        'background_portrait_landscape': PropTypes.exact({
            'landscape': PropTypes.object,
            'portrait': PropTypes.object,
        }),
    }).isRequired,
    isColumn: PropTypes.bool,
};

export default ImageFullscreenBlock;
