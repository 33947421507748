/* eslint-disable camelcase */
import { ContentComponent, ContentComponents } from 'libs/wordpress/utils/cmsComponentHandlers';

// The cmsBlocks are not imported with loadable to prevent a potential race condition error when cmsBlocks are lodaded inside cmsModules.
import CallToActionBlock from 'libs/wordpress/content/cmsBlocks/CallToActionBlock';
import ImageFullscreenBlock from 'libs/wordpress/content/cmsBlocks/ImageFullscreenBlock';
import ImageRatioBlock from 'libs/wordpress/content/cmsBlocks/ImageRatioBlock';
import PropTypes from 'prop-types';
import React from 'react';
import VideoInlineFullscreenBlock from 'libs/wordpress/content/cmsBlocks/VideoInlineFullscreenBlock';
import VideoInlineRatioBlock from 'libs/wordpress/content/cmsBlocks/VideoInlineRatioBlock';

// Object of avalible blocks and their keys
const availableContentBlocks = {
    // Add the cmsBlocks that exists in core/views directly in here.
    // We don't want to dynamically import them as that would cause circular references.
    'background_image_fullscreen_module': ImageFullscreenBlock,
    'background_image_ratio_module': ImageRatioBlock,
    'background_image_small_module': ImageRatioBlock,
    'call_to_action': CallToActionBlock,
    'video_inline_fullscreen_module': VideoInlineFullscreenBlock,
    'video_inline_ratio_module': VideoInlineRatioBlock,
    'video_inline_small_module': ImageRatioBlock,
};

/**
 * Accepts raw block data from the cms and tries to match the data to a cmsBlock.
 * If a match is found the cmsBlock is returned and rendered.
 * @param {(object|object[])} data - Raw block data from the cms. Can be a object or an array of objects.
 * @param {...*} rest - All additional props will be forwarded to the cmsBlock.
 */

const CmsBlocks = ({ data, ...rest }) => {
    if (!data) {
        return null;
    } else if (Array.isArray(data)) {
        return <ContentComponents data={data} availableContent={availableContentBlocks} {...rest} />;
    }
    return <ContentComponent data={data} availableContent={availableContentBlocks} {...rest} />;
};

CmsBlocks.propTypes = {
    data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
};

CmsBlocks.defaultProps = {
    data: undefined,
};

export default CmsBlocks;
