import Above from 'components/breakpoints/Above';
import Below from 'components/breakpoints/Below';
import CmsBlocks from 'libs/wordpress/content/CmsBlocks';
import ColumnsLayout from 'components/contentLayouts/ColumnsLayout';
import ExpandWrapper from 'components/wrappers/Animations/ExpandWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { mediaQueries } from 'config/theme/breakpoints';
import styled from 'libs/styled';
import useClientHeight from 'hooks/useClientHeight';

const Div = styled('div')``;

const ColumnsTwoFullscreenModule = ({ content = [], ...rest }) => {
    const clientHeight = useClientHeight();
    const halfClientHeight = `calc(${clientHeight} / 2)`;

    // We should not render the two column if the data is not correct.
    if (content?.length !== 2) {
        return null;
    }

    return (
        <Div as="section" height={clientHeight}>
            <ExpandWrapper>
                <ColumnsLayout
                    blocks={content.map((column, index) => (
                        <div key={`${column.layout}-${index}`}>
                            <Below
                                breakpoint="desktopSm"
                                render={() => (
                                    <Div height={halfClientHeight}>
                                        <CmsBlocks
                                            isColumn
                                            data={column}
                                            mediaQuery={mediaQueries.twoColumn}
                                            {...rest}
                                        />
                                    </Div>
                                )}
                            />
                            <Above
                                breakpoint="desktopSm"
                                render={() => (
                                    <Div height={clientHeight}>
                                        <CmsBlocks
                                            isColumn
                                            data={column}
                                            mediaQuery={mediaQueries.twoColumn}
                                            {...rest}
                                        />
                                    </Div>
                                )}
                            />
                        </div>
                    ))}
                    columnSizes={[12, null, null, null, 6]}
                    gutterHeight={0}
                    gutterWidth={0}
                />
            </ExpandWrapper>
        </Div>
    );
};

ColumnsTwoFullscreenModule.propTypes = {
    content: PropTypes.array,
};

export default ColumnsTwoFullscreenModule;
