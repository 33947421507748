import React, { useEffect, useRef, useState } from 'react';
import { backgroundFlexibleProp, backgroundTypeProp, mediaQueryProp } from 'utils/proptypes/modules/mediaProps';

import Arrow from 'assets/icons/Arrow';
import BackgroundSwitch from 'components/background/BackgroundSwitch';
import ExpandWrapper from 'components/wrappers/Animations/ExpandWrapper';
import Heading from 'components/text/Heading';
import Link from 'components/base/Link';
import PropTypes from 'prop-types';
import ScrollButton from 'components/scrollComponents/ScrollButton';
import Tag from 'components/text/Tag';
import { above } from 'utils/mediaqueries';
import clickOnLinkCursor from 'assets/icons/cursors/click_on_link.svg';
import contentMargins from 'config/theme/contentMargins';
import { headingTagProp } from 'utils/proptypes/modules/textProps';
import styled from 'libs/styled';

const Div = styled('div')``;

const CursorLinkArea = styled(Link)`
    width: 100%;
    height: 100%;
    display: flex;
    cursor: url('${clickOnLinkCursor}') 24 24, pointer;
`;

const InnerWrapper = styled('div', { shouldForwardProp: prop => ['centerContent'].indexOf(prop) === -1 })`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 100%;

    ${({ centerContent }) =>
        centerContent &&
        `
        justify-content: center;
        align-items: center;
  
    `}
`;

const Content = styled('div', {
    shouldForwardProp: prop => ['centerContent', 'fixedContentWidth'].indexOf(prop) === -1,
})`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 64px;

    ${above.desktopSm} {
        width: ${({ fixedContentWidth, centerContent }) =>
            fixedContentWidth ? `100%` : centerContent ? 'calc(66.66666666% - 4px)' : 'calc(33.33333333% - 8px)'};
    }

    ${({ centerContent }) =>
        centerContent &&
        `
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        text-align: center;
    `}
`;

const ArrowWrapper = styled('div', { shouldForwardProp: prop => ['centerContent'].indexOf(prop) === -1 })`
    cursor: pointer;

    ${({ centerContent }) =>
        centerContent
            ? `
        position: absolute;        
        left: 50%;
        bottom: 48px;
        transform: translateX(-50%);

        ${above.tabletSm}{
            bottom: 48px;
        }
    `
            : `
        ${above.tabletSm}{
            position: absolute;
            right: 24px;
            bottom: 64px;
        }  
    `}
`;

/**
 *  contentBlock used to display heading, tag and background with a link.
 *
 * @param {bool} activeAnimation - Option to use expand animation.
 * @param {object} background - Background object.
 * @param {string} backgroundType - Type of background if not supplied inside the background object.
 * @param {string} [centerContent=false] - Option to position content in the center.
 * @param {string} heading - Heading as a string.
 * @param {string||string[]} headingFontKeys - Fontkeys for heading.
 * @param {string} headingTag - The html-tag used by heading; h1, h2, h3 etc.
 * @param {bool} fixedContentWidth - Adjust design for when the module is used in a column.
 * @param {array} mediaQuery - Array of media-queries used by background image.
 * @param {bool} showScrollButton - Option to show scroll down button.
 * @param {string} tag - Small tag displayed below heading.
 * @param {string} to - Link url.
 */

const CallToAction = ({
    activeAnimation = true,
    background = {},
    backgroundType = '',
    centerContent = false,
    fixedContentWidth = false,
    heading = '',
    headingFontKeys = '',
    headingTag = '',
    mediaQuery = {},
    showScrollButton = false,
    tag = '',
    to = '',
    ...rest
}) => {
    const textRef = useRef();
    const [textWidth, setTextWidth] = useState(null);

    // Set a fixed content width if the module is used in a column for preventing the content to move if the parent width is changing
    useEffect(() => {
        setTextWidth(textRef.current?.getBoundingClientRect()?.width);
    }, [textRef]);

    return (
        <Div height="100%" position="relative" width="100%" {...rest}>
            <CursorLinkArea volatile to={to}>
                <ExpandWrapper active={activeAnimation}>
                    <BackgroundSwitch type={backgroundType} background={background} mediaQuery={mediaQuery}>
                        <InnerWrapper centerContent={centerContent} px={contentMargins}>
                            <Content centerContent={centerContent} fixedContentWidth={fixedContentWidth}>
                                <Div
                                    ref={textRef}
                                    width={[
                                        'calc(83.33333333% - 8px)',
                                        null,
                                        null,
                                        null,
                                        fixedContentWidth ? `${textWidth}px` : '100%',
                                    ]}
                                >
                                    <Heading
                                        as={headingTag}
                                        fontKeys={headingFontKeys}
                                        margin={tag ? '0 0 16px 0' : '0'}
                                    >
                                        {heading}
                                    </Heading>
                                    <Tag>{tag}</Tag>
                                </Div>
                                <ArrowWrapper centerContent={centerContent}>
                                    <Arrow color="currentColor" width={['32px', null, '40px', null, '48px']} />
                                </ArrowWrapper>
                                {showScrollButton && (
                                    <ScrollButton
                                        bottom="24px"
                                        left="50%"
                                        position="absolute"
                                        scrollDirection="down"
                                        transform="translateX(-50%)"
                                    />
                                )}
                            </Content>
                        </InnerWrapper>
                    </BackgroundSwitch>
                </ExpandWrapper>
            </CursorLinkArea>
        </Div>
    );
};

CallToAction.propTypes = {
    activeAnimation: PropTypes.bool,
    background: backgroundFlexibleProp,
    backgroundType: backgroundTypeProp.isRequired,
    centerContent: PropTypes.bool,
    fixedContentWidth: PropTypes.bool,
    heading: PropTypes.string.isRequired,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    headingTag: headingTagProp.isRequired,
    mediaQuery: mediaQueryProp.isRequired,
    showScrollButton: PropTypes.bool,
    tag: PropTypes.string.isRequired,
    to: PropTypes.string,
};

export default CallToAction;
