import { above } from 'utils/mediaqueries';
import { animations } from 'config/theme/transitions';
import contentMargins from 'config/theme/contentMargins';
import styled from 'libs/styled';
import withAnimation from 'utils/withAnimation';

// Activates an animation that goes from following the content margin to fullscreen when 50% has entered the screen.
const ExpandWrapper = withAnimation(
    styled('div', { shouldForwardProp: prop => ['active', 'animateIn'].indexOf(prop) === -1 })`
        width: 100%;
        height: 100%;

        ${({ animateIn = false, active = true }) =>
            active &&
            `
                transition-property: all;
                transition-duration: ${animations.secondary.duration}ms;
                transition-timing-function: ${animations.secondary.timingFunction};

            &[data-animate='true'] {
                border: ${
                    animateIn
                        ? '0 solid var(--theme-background-color)'
                        : `${contentMargins[0]} solid var(--theme-background-color)`
                };

                ${above.tabletSm} {
                    border: ${
                        animateIn
                            ? '0 solid var(--theme-background-color)'
                            : `${contentMargins[2]} solid var(--theme-background-color)`
                    };
                }
            }
        `}
    `,
    { threshold: 0.45, triggerOnce: false }
);

export default ExpandWrapper;
