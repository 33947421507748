import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundVideo from 'components/base/video/BackgroundVideo';
import ExpandWrapper from 'components/wrappers/Animations/ExpandWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import ratios from 'config/theme/ratios';

// Pure CMS data transformation before we send it to the "real" block!
const VideoInlineRatioBlock = ({ content = {}, isSmall = false, isColumn = false }) => {
    const { video_ratio: VideoRatio } = content;

    // Transform image data
    const video = VideoRatio.background_video.video;
    const ratio = VideoRatio.ratio === 'portrait' ? ratios.vertical : ratios.horizontal;

    if (!video) {
        return null;
    }

    return (
        <ExpandWrapper active={!isColumn && !isSmall}>
            <AspectWrapper ratio={ratio}>
                <BackgroundVideo src={video.url} />
            </AspectWrapper>
        </ExpandWrapper>
    );
};

VideoInlineRatioBlock.propTypes = {
    content: PropTypes.shape({
        'video_ratio': PropTypes.exact({
            'background_video': PropTypes.object,
            ratio: PropTypes.string,
        }),
    }).isRequired,
    isColumn: PropTypes.bool,
    isSmall: PropTypes.bool,
};

export default VideoInlineRatioBlock;
