import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundImage from 'components/background/BackgroundImage';
import ExpandWrapper from 'components/wrappers/Animations/ExpandWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { mediaQueries } from 'config/theme/breakpoints';
import ratios from 'config/theme/ratios';
import transformBackgroundImage from 'libs/wordpress/utils/transformBackgroundImage';

// Pure CMS data transformation before we send it to the "real" block!
const ImageFullscreenBlock = ({ content = {}, isColumn = false, isSmall = false }) => {
    const { image_ratio: imageRatio } = content;

    // Transform image data
    const transformedBackground = transformBackgroundImage(imageRatio.background_image);
    const ratio = imageRatio.ratio === 'portrait' ? ratios.vertical : ratios.horizontal;

    if (!transformedBackground) {
        return null;
    }

    return (
        <ExpandWrapper active={!isColumn && !isSmall}>
            <AspectWrapper ratio={ratio}>
                <BackgroundImage
                    src={transformedBackground.url}
                    position={transformedBackground.position}
                    size={transformedBackground.backgroundSize}
                    query={mediaQueries.fullWidth}
                />
            </AspectWrapper>
        </ExpandWrapper>
    );
};

ImageFullscreenBlock.propTypes = {
    content: PropTypes.shape({
        'image_ratio': PropTypes.exact({
            'background_image': PropTypes.object,
            ratio: PropTypes.string,
        }),
    }).isRequired,
    isColumn: PropTypes.bool,
    isSmall: PropTypes.bool,
};

export default ImageFullscreenBlock;
