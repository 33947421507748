import CallToAction from 'components/contentBlocks/CallToAction';
import PropTypes from 'prop-types';
import React from 'react';
import decodeHTMLEntities from '@nwg/core-web/utils/decodeHTMLEntities';
import { mediaQueries } from 'config/theme/breakpoints';
import transformFlexibleBackground from 'libs/wordpress/utils/transformFlexibleBackground';
import transformLink from 'libs/wordpress/utils/transformLink';
import transformTextColor from 'libs/wordpress/utils/transformTextColor';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

// Pure CMS data transformation before we send it to the "real" block!
const CallToActionBlock = ({ content = {}, isColumn = false, isHero = false }) => {
    const { background_media_flexible: backgroundFlexible, heading, link, tag, text_color: textColor } = content;

    // Transform background data
    const isAboveDesktopSm = useAboveBreakpoint('desktopSm');
    const transformedBackground = transformFlexibleBackground(backgroundFlexible);
    const background = isAboveDesktopSm ? transformedBackground?.desktop : transformedBackground?.mobile;
    const backgroundType = isAboveDesktopSm
        ? transformedBackground?.desktop?.type
        : transformedBackground?.mobile?.type;

    return (
        <CallToAction
            activeAnimation={!isColumn && !isHero}
            background={background}
            backgroundType={backgroundType}
            centerContent={!isHero}
            color={transformTextColor(textColor)}
            fixedContentWidth={isColumn}
            heading={heading ? decodeHTMLEntities(heading.text) : null}
            headingFontKeys={[
                isColumn ? 'Value Serif/32-scalable-mobile' : 'Value Serif/40-scalable-mobile',
                null,
                'Value Serif/56-scalable-tablet',
                null,
                isHero || isColumn ? 'Value Serif/32-scalable-desktop' : 'Value Serif/80-scalable-desktop',
            ]}
            headingTag={heading ? heading.type : null}
            mediaQuery={mediaQueries.maxWidth}
            showScrollButton={isHero}
            tag={tag}
            to={link ? transformLink(link).to : null}
        />
    );
};

CallToActionBlock.propTypes = {
    content: PropTypes.shape({
        'background_media_flexible': PropTypes.exact({
            mobile: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
            desktop: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object])),
        }).isRequired,
        link: PropTypes.object,
        heading: PropTypes.object,
        tag: PropTypes.string,
        paragraph: wysiwygProp,
    }),
    isColumn: PropTypes.bool,
    isHero: PropTypes.bool,
};

export default CallToActionBlock;
