import BackgroundVideo from 'components/base/video/BackgroundVideo';
import ExpandWrapper from 'components/wrappers/Animations/ExpandWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';

// Pure CMS data transformation before we send it to the "real" block!
const VideoInlineFullscreenBlock = ({ content = {}, isColumn = false }) => {
    const { background_video_portrait_landscape: backgroundVideoPortraitLandscape } = content;

    // Transform video data
    const isAboveTabletSm = useAboveBreakpoint('tabletSm');
    const video = isAboveTabletSm
        ? backgroundVideoPortraitLandscape.landscape?.video
        : backgroundVideoPortraitLandscape.portrait?.video;

    if (!video) {
        return null;
    }

    return (
        <ExpandWrapper active={!isColumn}>
            <BackgroundVideo src={video.url} />
        </ExpandWrapper>
    );
};

VideoInlineFullscreenBlock.propTypes = {
    content: PropTypes.shape({
        'background_video_portrait_landscape': PropTypes.exact({
            landscape: PropTypes.object,
            portrait: PropTypes.object,
        }),
    }).isRequired,
    isColumn: PropTypes.bool,
};

export default VideoInlineFullscreenBlock;
